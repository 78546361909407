<template>
  <div class="roomlist">
    <div class="list-title">参观目录</div>
    <div class="list-row2">
      <div class="list-display" v-for="o in listData" :key="o.id">
        <div class="box-card" @click="toDetail(o.id)">
          <div class="text item">
            <img :src="getImg(o.id)" />
            <div class="pic-title">{{o.name}}</div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div>
      <el-card class="box-card">
        <div v-for="o in 4" :key="o" class="text item">
          {{'列表内容 ' + o }}
        </div>
      </el-card>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'RoomList',
  methods: {
    toDetail: function (id) {
      this.$router.push(`/detail/${id}`)
      // console.log(id, '=====id======');
    },
    getImg: function (id) {
      return require("@/assets/" + id + ".png");
      // return require(`@/assets/{$id}.png`);
    }
  }
}
</script>

<style lang="less" src="./List.less"></style>